*,
*::before,
*::after {
  box-sizing: border-box;
}

@supports (-webkit-overflow-scrolling: touch) {
  input {
    font-size: 16px;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
  box-shadow: 0 0 0 30px white inset;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

html {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: Helvetica Neue, Courier New, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
  font-size: 16px;
  line-height: 1.5;
  color: #595959;
  text-align: left;
  background-color: #fff;
}
[tabindex="-1"]:focus {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[data-original-title],
abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  font-style: normal;
  line-height: inherit;
}
address,
dl,
ol,
ul {
  margin-bottom: 1rem;
}
dl,
ol,
ul {
  margin-top: 0;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #336;
  background-color: transparent;
}
a,
a:hover {
  text-decoration: none;
}
a:hover {
  color: #595959;
}
a:not([href]):not([tabindex]),
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}
figure {
  margin: 0 0 1rem;
}
img {
  border-style: none;
}
img,
svg {
  vertical-align: middle;
}
svg {
  overflow: hidden;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #999;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 5px auto -webkit-focus-ring-color;
}
button:focus-visible {
  outline: 3px solid #52addb;
}
button,
input,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
select {
  margin: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
select {
  word-wrap: normal;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="time"] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: bold;
  line-height: 1.2;
  color: #336;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.75rem;
}
h3 {
  font-size: 1.375rem;
}
h4 {
  font-size: 1.125rem;
}
h5 {
  font-size: 1rem;
}
h6 {
  font-size: 1rem;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
small {
  font-size: 80%;
  font-weight: 600;
}
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

code {
  font-size: 87.5%;
  color: #e71921;
  word-break: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}
pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

@font-face {
  font-family: Helvetica Neue;
  src: url("./static/fonts/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix") format("eot"),
    url("./static/fonts/3dac71eb-afa7-4c80-97f0-599202772905.woff2") format("woff2"),
    url("./static/fonts/34e0e4c0-c294-49bb-9a8e-1b2cafd54e32.woff") format("woff"),
    url("./static/fonts/8a8bfee3-197c-4942-9b11-71508cc9f406.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: Helvetica Neue;
  src: url("./static/fonts/5640036d-d549-4f16-b31e-87da78d9e832.eot?#iefix") format("eot"),
    url("./static/fonts/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2") format("woff2"),
    url("./static/fonts/7802e576-2ffa-4f22-a409-534355fbea79.woff") format("woff"),
    url("./static/fonts/14d824a2-2a5c-4692-8e30-74f6e177b675.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Helvetica Neue;
  src: url("./static/fonts/9697cfef-7816-47cc-81ed-c56c029d3bcf.eot?#iefix") format("eot"),
    url("./static/fonts/1f4274cd-2674-481e-9023-69e33ffca161.woff2") format("woff2"),
    url("./static/fonts/5e8d493c-be01-44b9-962e-504a5cf10dd8.woff") format("woff"),
    url("./static/fonts/ae36b4a0-aa2c-46cc-98d8-d8b7d22241dc.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Domine:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:ital,wght@0,300;0,700;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");
